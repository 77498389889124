var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadinDetail),expression:"loadinDetail"}],staticClass:"detailPage"},[_c('div',{staticClass:"detail_heade"},[_c('div',{staticClass:"breadcrumb"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},_vm._l((_vm.breadcrumbData),function(val,index){return _c('el-breadcrumb-item',{key:index,attrs:{"to":index == 2
              ? {
                  name: 'indexPage',
                  params: { detailId: val.menuId },
                  query: { module: val.menuId },
                }
              : ''}},[_vm._v(_vm._s(val.label))])}),1)],1),_c('div',{staticClass:"operation"},[_c('p',{staticClass:"copyLink",on:{"click":_vm.handleCopy}},[_vm._v("Copy Link")]),_c('p',{staticClass:"downloadPDF",on:{"click":_vm.downloadPDF}},[_vm._v(" Download PDF ")]),_c('div',{staticClass:"language"},[_c('el-select',{on:{"change":_vm.handleLanguage},model:{value:(_vm.languageValue),callback:function ($$v) {_vm.languageValue=$$v},expression:"languageValue"}},_vm._l((_vm.languageOption),function(item){return _c('el-option',{key:item.languageCode,attrs:{"label":item.languageName,"value":item.languageCode}})}),1)],1),(!_vm.isArticle)?_c('div',{staticClass:"version"},[_c('el-select',{attrs:{"placeholder":"Version"},on:{"change":_vm.handleVersion},model:{value:(_vm.versionValue),callback:function ($$v) {_vm.versionValue=$$v},expression:"versionValue"}},_vm._l((_vm.versionOption),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1):_vm._e()])]),_c('mainContent',{ref:"mainContentRef",on:{"getBreadcrumbData":function($event){_vm.breadcrumbData = $event},"getIsArticle":function($event){_vm.isArticle = $event},"getLoadinDetail":function($event){_vm.loadinDetail = $event},"getVersion":function($event){_vm.versionValue = $event.versionValue;
      _vm.versionOption = $event.versionOption;},"getLanguage":function($event){_vm.languageValue = $event.languageValue;
      _vm.languageOption = $event.languageOption;}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }