<template>
  <div class="detailPage" v-loading="loadinDetail">
    <div class="detail_heade">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            v-for="(val, index) in breadcrumbData"
            :key="index"
            :to="
              index == 2
                ? {
                    name: 'indexPage',
                    params: { detailId: val.menuId },
                    query: { module: val.menuId },
                  }
                : ''
            "
            >{{ val.label }}</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="operation">
        <p class="copyLink" @click="handleCopy">Copy Link</p>
        <p class="downloadPDF" @click="downloadPDF">
          Download PDF
        </p>
        <div class="language">
          <el-select v-model="languageValue" @change="handleLanguage">
            <el-option
              v-for="item in languageOption"
              :key="item.languageCode"
              :label="item.languageName"
              :value="item.languageCode"
            ></el-option>
          </el-select>
        </div>
        <div class="version" v-if="!isArticle">
          <el-select
            v-model="versionValue"
            @change="handleVersion"
            placeholder="Version"
          >
            <el-option
              v-for="item in versionOption"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <mainContent
      ref="mainContentRef"
      @getBreadcrumbData="breadcrumbData = $event"
      @getIsArticle="isArticle = $event"
      @getLoadinDetail="loadinDetail = $event"
      @getVersion="
        versionValue = $event.versionValue;
        versionOption = $event.versionOption;
      "
      @getLanguage="
        languageValue = $event.languageValue;
        languageOption = $event.languageOption;
      "
    />
  </div>
</template>

<script>
import { sendHtml } from "@/api/common";
import mainContent from "./mainContent.vue";
export default {
  name: "detailPage",
  components: { mainContent },
  data() {
    return {
      loadinDetail: false,
      breadcrumbData: [],
      //语言下拉框
      languageValue: "",
      languageOption: [],
      //版本下拉框
      versionValue: "",
      versionOption: [],

      pdfUrl: "",
      isArticle: false,
    };
  },
  methods: {
    //点击复制
    handleCopy() {
      const domUrl = document.createElement("input");
      domUrl.value = window.location.href;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      const creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      this.$message({
        message: "Copy success",
        type: "success",
      });
    },

    //文章转pdf下载
    downloadPDF() {
      let params = {
        filename: this.$refs.mainContentRef.detailData.title,
        articleId: this.$refs.mainContentRef.detailData.articleId,
        html: `<!DOCTYPE html>
        <html lang="zh">
        <head><meta charset="utf-8" /></head>
        <body>
        ${this.$refs.mainContentRef.detailData.content}
        </body>
        </html>`,
      };
      sendHtml(params).then((res) => {
        let blob = new Blob([res.data], { type: "application/pdf" });
        this.pdfUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        const filename = this.$refs.mainContentRef.detailData.title;
        a.href = this.pdfUrl;
        // 判断文件名是否以.pdf文件
        if (filename.endsWith(".pdf")) {
          a.download = filename;
        } else {
          a.download = filename + ".pdf";
        }
        a.click();
      });
    },
    //切换语言版本
    handleLanguage(val) {
      this.languageValue = val;
      this.$refs.mainContentRef.handleLanguage(val, this.versionValue);
    },
    //切换设备版本
    handleVersion(val) {
      this.versionValue = val;
      this.$refs.mainContentRef.handleVersion(val, this.languageValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.detailPage {
  width: 100%;
  .detail_heade {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 19px 0;
    background: #324062;
    .breadcrumb {
      flex: 5;
      padding-left: 30px;
    }
    .operation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .copyLink {
        font-size: 16px;
        cursor: pointer;
        color: #fff;
        margin-right: 20px;
        background: #71b32f;
        padding: 10px 15px;
        border-radius: 25px;
      }
      .downloadPDF {
        font-size: 16px;
        cursor: pointer;
        color: #fff;
        margin-right: 10px;
        padding: 10px 15px;
        background: #71b32f;
        border-radius: 25px;
      }
      .language {
        margin-right: 10px;
      }
      .version {
        margin-right: 10px;
      }
    }
  }
}
//图片放大
.imgDolg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  background-color: rgba(140, 134, 134, 0.6);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  #imgDolgClose {
    position: fixed;
    top: 19px;
    cursor: pointer;
    right: 7%;
    font-size: 50px;
    color: white;
  }
  img {
    width: 90%;
  }
}
.el-breadcrumb__item:last-child /deep/.el-breadcrumb__inner {
  cursor: pointer;
}
.borderBottom {
  border-bottom: 1px solid #ccc;
}
</style>
<style>
table {
  border: rgb(236, 237, 239) 1px solid;
}
table tr {
  border: rgb(236, 237, 239) 1px solid;
}
table tr th {
  border: rgb(236, 237, 239) 1px solid;
}
table tr td {
  border: rgb(236, 237, 239) 1px solid;
}
table tr:nth-child(even) {
  background: rgb(248, 248, 248);
}
thead {
  background: rgb(248, 248, 248);
}
</style>
